/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
import React from 'react'
// import { CountryComponent } from './country'
import { connect } from 'react-redux'
import { getVerificationData } from '../store/action'
import * as faceApi from 'face-api.js'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
// import { Carousel } from 'react-responsive-carousel'
import IdentityVerificationComponent from '../components/country/index'
import introAnimationFile from '../assets/animation/animation.json'
import Lottie from 'react-lottie'

class IntroScreenComponent extends React.Component {
  state = {
    token: '',
    error_message: '',
    showAlert: false,
    data: {},
    country: null,
    showIntro: true,
    modelIsloading: false
  }

  componentDidMount = async () => {
    const token = this.props.match.params.token
    // Make a request to get token details
    this.props.dispatch(getVerificationData(token))
    try {
      this.setState({ modelIsloading: true })
      await faceApi.nets.tinyFaceDetector.loadFromUri('/models/')
      await faceApi.loadFaceExpressionModel('/models/')
      this.setState({ modelIsloading: false })
    } catch (e) {
      console.log(e)
      this.setState({ modelIsloading: false })
      this.log(e.name, e.message, e.stack)
    }
  }

  closeVerification = () => {
    // dispatch success event for parent element
    var event_response = {
      eventInstance: 'identity_verification_event',
      data: {
        status: 'failed',
        message: 'User Verification Cancelled'
      }
    }
    window.parent.postMessage(event_response, '*')
  }

  setCountry = (country) => {
    this.setState({ country })
  }

  render() {
    function LightenDarkenColor(col, amt) {
      var usePound = false

      if (col === undefined) return null

      if (col[0] === '#') {
        col = col.slice(1)
        usePound = true
      }

      var num = parseInt(col, 16)

      var r = (num >> 16) + amt

      if (r > 255) r = 255
      else if (r < 0) r = 0

      var b = ((num >> 8) & 0x00FF) + amt

      if (b > 255) b = 255
      else if (b < 0) b = 0

      var g = (num & 0x0000FF) + amt

      if (g > 255) g = 255
      else if (g < 0) g = 0

      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    }
    const darkColor = LightenDarkenColor(this.props.checkoutState?.merchant_config?.configuration?.theme_color, -30)
    const darkestColor = LightenDarkenColor(this.props.checkoutState?.merchant_config?.configuration?.theme_color, -60)
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: introAnimationFile,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    if (this.state.showIntro) {
      return (
        <div className='id-checker-widget-area'>
          <div className='col-md-8 col-lg-5 preview-area mx-auto mt-md-5 p-0'>
            <button className='btn close-btn' onClick={this.closeVerification}>
              <i className='feather icon-x' />
            </button>
            <div className='card '>
              <div className='card-body'>
                <div className='carousel-body mt-4'>
                  <Lottie
                    options={defaultOptions}
                    height={280}
                    width={280}
                    style={{ marginBottom: 30 }}
                  />
                  <div className='intro-text-section'>
                    <h4 className='intro-text-header'>Important information</h4>
                    <p className='intro-text-body'>First, be in a very lit enviroment. After that position your face in the camera frame. Then move your head in a circle to show all the angles of your face and follow the instructions</p>
                  </div>
                </div>

                <div className='carousel-footer'>
                  <button
                    disabled={this.props.checkoutState.error || (this.props.checkoutState.loading || this.state.modelIsloading)}
                    className='btn btn-purple w-100 py-3 mb-3'
                    style={{ marginTop: 25, background: `linear-gradient(${darkColor},${darkestColor})` }}
                    onClick={() => {
                      this.setState({ showIntro: false })
                    }} type='button'
                  >
                    {(this.props.checkoutState.loading || this.state.modelIsloading) ? '....Please wait....' : 'Proceed'}
                  </button>
                  {/* <small> Powered by <span style={{ fontWeight: 600 }}> IdentityPass</span></small> */}
                  <div className=' widget-footer'>
                    <small> Powered by <span style={{ fontWeight: 600 }}> IdentityPass</span></small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return <IdentityVerificationComponent backAction={() => this.setState({ showIntro: true })} />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    checkoutState: state.checkout
  }
}
export default connect(mapStateToProps, null, null)(IntroScreenComponent)
