/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import Webcam from 'react-webcam'
import * as faceApi from 'face-api.js'
import { useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert'
import { BackButton } from '../back_btn'

export const CameraComponent = (props) => {
  const [image, setImage] = useState('')
  const [error, setError] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const [expressions, setExpression] = useState([])
  const [step, setStep] = useState(0)
  const [faceDetected, setFaceDetected] = useState(false)
  const [smileDetected, setSmileDetected] = useState(false)
  const [faceError, setFaceError] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [cameraLoading, setCameraLoading] = useState(true)

  const [stepDescription, setStepDescription] = useState(['Place your face in the circle for easier detection', 'Now smile cause we are almost done 😄', 'Now hold still and click on the complete button'])
  const camRef = React.useRef(null)

  const checkoutState = useSelector((state) => state.checkout)

  const capture = React.useCallback(() => {
    const image_src = camRef.current.getScreenshot().split(',')[1]
    setImage(image_src)
    props.onCapture(image_src)
  },
    [camRef]
  )

  const initializeFaceCapture = async () => {
    if (faceDetected) {
      setStep(step + 1)
      setFaceError(false)
      setSmileDetected(false)
      setError('')
    } else {
      setFaceError(true)
      setError('Face not detected yet, place your face on the circle')
    }
  }

  const finalizeFaceCapture = async () => {
    if (image) {
      setFaceError(false)
      setError('')
      props.onCapture(image)
    } else if (faceDetected && smileDetected) {
      const image_src = camRef.current.getScreenshot().split(',')[1]
      setImage(image_src)
      setFaceError(false)
      setError('')
      props.onCapture(image_src)
    } else {
      setFaceError(true)
      setError('Face liveliness failed, Please smile a bit😄 ')
    }
  }

  const videoConstraints = {
    facingMode: props.facingMode,
    height: 230
  }

  const runFaceDetectionModel = async () => {
    try {
      await faceApi.nets.tinyFaceDetector.loadFromUri('/models/')
      await faceApi.loadFaceExpressionModel('/models/')
    } catch (e) {
      this.log(e.name, e.message, e.stack)
    }
  }

  const detectFace = async () => {
    const video_ref = document.getElementById('webcam')
    if (
      video_ref?.paused ||
      image
    ) {
      setTimeout(() => detectFace())
      return
    }
    if (video_ref) {
      const options = new faceApi.TinyFaceDetectorOptions({
        inputSize: 512,
        scoreThreshold: 0.5
      })

      // console here gives an array of undefined
      const result = await faceApi
        .detectSingleFace(video_ref, options)
        .withFaceExpressions()

      if (result) {
        setFaceDetected(true)
        // setStep(1)
        if (step === 0) {
          setFaceError(false)
        }

        setError('')

        if (result.expressions.happy < 0.60) {
          if (step === 1) {
            setFaceError(false)
          }
        } else {
          if (step === 1) {
            video_ref.pause()
          }

          setSmileDetected(true)
          setStep(2)
        }
        setExpression(expressions)
      }
      setTimeout(() => detectFace(), 500)
    }
  }

  function LightenDarkenColor(col, amt) {
    var usePound = false

    if (col === undefined) return null

    if (col[0] === '#') {
      col = col.slice(1)
      usePound = true
    }

    var num = parseInt(col, 16)

    var r = (num >> 16) + amt

    if (r > 255) r = 255
    else if (r < 0) r = 0

    var b = ((num >> 8) & 0x00FF) + amt

    if (b > 255) b = 255
    else if (b < 0) b = 0

    var g = (num & 0x0000FF) + amt

    if (g > 255) g = 255
    else if (g < 0) g = 0

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  }
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  return (
    <div className='checkout'>
      <div className='checkout-container'>
        <BackButton onClick={props.dismissCamera} />
        <h5 className='mt-3'>{props.header}</h5>
        {(props.facingMode === 'user' &&
          <div><p className='m-0' style={{ color: '#172b4d' }}>Step {step + 1} of 3</p>
            <div className='progress-area mb-4'>
              {
                step === 2
                  ? <div className='fully-completed' />
                  : <div className='half-completed' />
              }
            </div>
          </div>
        )}
        <div className='input mx-auto'>
          <div className={props.facingMode === 'user' ? 'cornered camera-image-section' : 'cornered camera-image-section  camera-image-section-front'}>
            {cameraLoading && <div className='webcam-class camera-loading'> ... Camera is loading ...</div>}
            {!image
              ? <Webcam
                  audio={false}
                  ref={camRef}
                  className={props.facingMode === 'user' ? 'webcam-class' : 'webcam-class-front'}
                  id='webcam'
                  style={{ opacity: cameraLoading ? 0 : 1 }}
                  screenshotFormat='image/jpeg'
                  screenshotQuality={1}
                  onPlay={props.facingMode === 'user' ? detectFace : null}
                  videoConstraints={videoConstraints}
                  onUserMedia={(a) => {
                  setCameraLoading(false)
                  }}
                  onUserMediaError={(err) => {
                  setCameraLoading(false)
                  if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
                    setError('We could not detect a camera on this device')
                    setErrorCode('NO_CAMERA')
                    // required track is missing
                  } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
                    // webcam or mic are already in use
                    setError('Camera already in use...please stop any application currrently using your camera and try again.')
                    setErrorCode('CAMERA_IN_USE')
                  } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
                    // constraints can not be satisfied by avb. devices
                    setError(props.facingMode.toUpperCase() + ' mode not supported by your camera')
                    setErrorCode('MODE_NOT_SUPPORTED')
                  } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                    // permission denied in browser
                    setError('Please enable camera permission to capture your face')
                    setErrorCode('PERMISSION')
                  } else if (err.name === 'TypeError' || err.name === 'TypeError') {
                    // empty constraints object
                  } else {
                    // other errors
                    setErrorCode('UNAVAILABLE')
                    setError('We could not load the device camera, please try again')
                  }
                  }}
                />
              : <img src={'data:image/jpeg;base64,' + image} className='webcam-class' />}
          </div>
        </div>
        <div>

          {error || props.error
            ? <Alert className='error-alert' key='error' variant='warning' style={{ color: '#091E42', backgroundColor: '#FFF0B3', fontWeight: 500 }}><i className='fa fa-eye' />{error || props.error} </Alert>
            : props.facingMode === 'user' ? <p className='camera-step-description'>{stepDescription[step]}</p> : null}

        </div>

        {
          ((props.facingMode === 'user' && step === 0) &&
            <button
              disabled={!!props.loading || !faceDetected}
              style={{ marginTop: 10, background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-3'
              onClick={initializeFaceCapture}
              type='button'
            >
              {
                faceError ? (
                  'Try Again') : !faceDetected ? 'Detecting your face' : 'Face detected, Click to Proceed'
              }
            </button>
          )
        }

        {
          ((props.facingMode === 'user' && (step === 1 || step === 2)) &&
            <button
              disabled={!!props.loading || !smileDetected}
              style={{ marginTop: 10, background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-3'
              onClick={finalizeFaceCapture}
              type='button'
            >
              {
                faceError ? (
                  'Try Again') : (step === 1 || step === 2) && props.loading ? 'Processing' : !smileDetected ? 'Detecting your smile' : 'Complete'
              }
            </button>
          )
        }

        {
          ((props.facingMode !== 'user' && error === '')) &&
            <button
              disabled={!!props.loading}
              style={{ marginTop: 10, background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-3'
              onClick={capture}
              type='button'
            >
              {props.loading ? (
                <div>
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                  Please wait
                </div>
            ) : (' Capture')}
            </button>

        }
      </div>
    </div>
  )
}
