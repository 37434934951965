/* eslint-disable camelcase */
import React from 'react'
import { Col } from 'react-bootstrap'
import NigeriaVerificationComponent from './Nigeria'

export const CountryVerificationComponent = (props) => {
  if (props.country === 'NG') {
    return <NigeriaVerificationComponent error={props.error} token={props.token} setCountry={props.setCountry} setActiveEndpoint={props.setActiveEndpoint} setActiveEntity={props.setActiveEntity} />
  } else {
    return (
      <Col className='text-center pd-t-20'>
        <h4>Country not available yet</h4>
      </Col>
    )
  }
}
