import { combineReducers } from 'redux'
import * as actionType from '../action_types'

const initialState = {
  loading: false,
  error: null,
  merchant_config: {},
  banks: []
}
function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.CHECKOUT_LOADING:
      return { ...state, error: false, loading: true }
    case actionType.LOAD_VERIFICATION_DATA:
      return { ...state, merchant_config: action.payload, error: null, loading: false }
    case actionType.BANK_SUCCESS:
      return { ...state, banks: action.payload, error: null, loading: false }
    case actionType.CHECKOUT_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

// other reducers needs to import here
const rootReducers = combineReducers({
  checkout: checkoutReducer
  // if there are other reducers , we can add here one by one
})
export default rootReducers
