import React from 'react'
import LightenDarkenColor from '../../util/changecolor'
import { useSelector } from 'react-redux'

export const BackButton = (props) => {
  const checkoutState = useSelector((state) => state.checkout)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)
  return (
    <>
      <button className='btn back-btn p-0' style={{ font: '14px C-bold', padding: 5 }} onClick={props.onClick}>
        <div style={{ padding: 3, paddingLeft: 5, paddingRight: 10 }}><i className='feather icon-arrow-left mr-2' style={{ color: darkestColor, fontWeight: 'bold' }} />
          Back
        </div>

      </button>
    </>
  )
}

// export const BackButton = () => {
//   const history = useHistory()
//   return (
//     <>
//       <button className='btn back-btn' key={index} onClick={() => this.setState({ activeEndpoint: value.endpoint.code })}>
//         <i className='feather icon-arrow-left mx-auto' />
//       </button>
//     </>
//   )
// }
