import * as actionType from '../action_types'
import axiosInstance from '../../util/axios'

export const getVerificationData = (token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: actionType.CHECKOUT_LOADING, payload: null })
    axiosInstance
      .post('biometrics/merchant/data/verification/library/initializer/get', {
        token
      })
      .then((response) => {
        dispatch({ type: actionType.LOAD_VERIFICATION_DATA, payload: response.data[0] })
        resolve(response.data)
      })
      .catch(function (error) {
        // handle error
        var errorMessage =
                    error.message === 'Network Error'
                      ? 'You seem to be offline'
                      : error.response?.data?.detail
                        ? error.response?.data?.detail
                        : 'Invalid Verification Reference'

        dispatch({ type: actionType.CHECKOUT_ERROR, payload: errorMessage })
        reject(errorMessage)
      })
      .then(function () {
        // always executed
      })
  })
}

export const getBanks = (token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: actionType.CHECKOUT_LOADING, payload: null })

    axiosInstance
      .get('biometrics/merchant/data/verification/bank_code')
      .then((response) => {
        dispatch({ type: actionType.BANK_SUCCESS, payload: response.data.data })
        resolve(response.data.data)
      })
      .catch(function (error) {
        // handle error
        var errorMessage =
                      error.message === 'Network Error'
                        ? 'You seem to be offline'
                        : error.response.data.detail
                          ? error.response.data.detail
                          : 'An error occurred...please contact admin'
        dispatch({ type: actionType.CHECKOUT_ERROR, payload: errorMessage })
        reject(errorMessage)
      })
      .then(function () {
        // always executed
      })
  })
}
