import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import axiosInstance from '../../../../util/axios'
import LightenDarkenColor from '../../../../util/changecolor'
import { BackButton } from '../../../back_btn'
import { CameraComponent } from '../../../camera'

export const InternationalPassportVerificationComponent = (props) => {
  const [number, setNumber] = useState(null)
  const [numberError, setNumberError] = useState('')
  const [firstName, setFirstName] = useState(null)
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState(null)
  const [lastNameError, setLastNameError] = useState('')
  const [dob, setDob] = useState(null)
  const [dobError, setDobError] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showSelfie, setShowSelfie] = useState(false)
  const numberRef = useRef(number)
  numberRef.current = number

  const checkoutState = useSelector((state) => state.checkout)
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const verifyIntPassport = (image) => {
    const data = {
      number,
      token: checkoutState.merchant_config?.token,
      first_name: firstName,
      last_name: lastName,
      dob: dob,
      image
    }

    setLoading(true)
    // Make a request to get international passport Info information
    axiosInstance
      .post('biometrics/merchant/data/verification/library/ng/passport', data)
      .then((response) => {
        // handle success
        if (response.data.status) {
          setError('')
          props.onSuccess(response.data, 'Int. Passport')
        } else {
          setError(response.data.message)
        }
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        if ('detail' in error.response.data) {
          if ('number' in error.response.data.detail) {
            setError(error.response.data.detail.number[0])
          } else {
            setError(error.response.data.detail)
          }
        }
        setLoading(false)
      })
      .then(function () {
        setLoading(false)
        // always executed
      })
  }

  const dismissCamera = () => {
    setShowSelfie(false)
    setError('')
  }

  const onFormSubmit = () => {
    if (!number) {
      setNumberError('Int. Passport ID cannot be Empty')
      return
    }
    if (!firstName) {
      setFirstNameError('FirstName cannot be Empty')
      return
    }
    if (!lastName) {
      setLastNameError('LastName cannot be Empty')
      return
    }
    if (!dob) {
      setDobError('Date of Birth cannot be Empty')
    } else {
      setNumberError(null)
      setShowSelfie(true)
      return false
    }
  }

  if (showSelfie) {
    return <CameraComponent onCapture={verifyIntPassport} dismissCamera={dismissCamera} loading={loading} error={error} facingMode='user' header='Capture your selfie' />
  }

  return (
    <div className='checkout'>
      <div className='checkout-container'>
        <>
          <div className='header'>
            <BackButton onClick={props.onBackBtnClick} />
            <h3 className='heading-3'>International Passport</h3>
          </div>

          <form action='' onSubmit={(e) => e.preventDefault()}>
            <div className='mb-2 mt-2'>
              <label>Int. Passport ID</label>
              <input
                type='text'
                name='IntPassportID'
                value={number}
                className='form-control'
                onChange={(value) => setNumber(value.target.value)}
                placeholder='A00000000'
              />
              {numberError ? <p className='error-text p-0 m-0'> {numberError} </p> : null}
            </div>
            <div className='row'>
              <div className='mb-2 mt-2 col-6'>
                <label>First Name</label>
                <input
                  type='text'
                  name='FirstName'
                  value={firstName}
                  className='form-control'
                  onChange={(value) => setFirstName(value.target.value)}
                  placeholder='John'
                />
                {firstNameError ? <p className='error-text p-0 m-0'> {firstNameError} </p> : null}
              </div>
              <div className='mb-2 mt-2 col-6'>
                <label>Last Name</label>
                <input
                  type='text'
                  name='LastName'
                  value={lastName}
                  className='form-control'
                  onChange={(value) => setLastName(value.target.value)}
                  placeholder='Doe'
                />
                {lastNameError ? <p className='error-text p-0 m-0'> {lastNameError} </p> : null}
              </div>
            </div>

            <div className='mb-5 mt-2'>
              <label>Date of birth</label>
              <input
                type='date'
                name='Dob'
                className='form-control'
                value={dob}
                onChange={(value) => setDob(value.target.value)}
                placeholder=''
              />
              {dobError ? <p className='error-text p-0 m-0'> {dobError} </p> : null}
            </div>
          </form>

          <div className=' widget-footer'>
            <button
              style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-5 mt-5'
              onClick={onFormSubmit}
              type='button'
            >
              Proceed
            </button>
          </div>
        </>

      </div>
    </div>
  )
}
