/* eslint-disable camelcase */
import React, { useState } from 'react'
import axiosInstance from '../../../../../util/axios'
import { Spinner } from 'react-bootstrap'
import { BackButton } from '../../../../back_btn'
import { useSelector } from 'react-redux'
import LightenDarkenColor from '../../../../../util/changecolor'
import Alert from 'react-bootstrap/Alert'

export const CACNGVerificationComponent = (props) => {
  const [number, setNumber] = useState('')
  const [numberError, setNumberError] = useState('')
  const [loading, setLoading] = useState(false)
  const [companyData, setCompanyData] = useState(null)
  const [error, setError] = useState('')
  const checkoutState = useSelector((state) => state.checkout)

  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const verifyCompany = () => {
    const data = {
      rc_number: number,
      token: checkoutState.merchant_config?.token
    }
    setLoading(true)
    // Make a request to get account information
    axiosInstance
      .post('biometrics/merchant/data/verification/library/ng/business/cac', data)
      .then((response) => {
        // handle success
        if (response.data.status) {
          setCompanyData(response.data)
          setError('')
          props.onSuccess(companyData, 'CAC')
        } else {
          setError(response.data.message)
        }
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        if ('detail' in error.response.data) {
          if ('number' in error.response.data.detail) {
            setError(error.response.data.detail.number[0])
          } else {
            setError(error.response.data.message)
          }
        }
        setLoading(false)
      })
      .then(function () {
        setLoading(false)
        // always executed
      })
  }

  const validateAndProceed = () => {
    if (!number) {
      setNumberError('RC/BN cannot be empty')
    } else {
      verifyCompany()
    }
  }
  return (
    <div className='checkout'>
      <BackButton onClick={props.onBackBtnClick} />
      <div className='checkout-container'>
        <div className='header mb-2'>
          <h3 className='heading-3'>Cooperate Affairs Commission</h3>
        </div>
        <form action='' onSubmit={(e) => e.preventDefault()}>
          <div className='mt-5 mb-4'>
            <label>Enter your RC/BN number</label>
            <input
              type='number'
              className='form-control'
              value={number}
              onChange={(value) => setNumber(value.target.value)}
              name='rc_number'
              placeholder='Enter RC/BN e.g 1456728'
            />
            {numberError ? <p className='error-text p-0 m-0'> {numberError} </p> : null}
          </div>
        </form>
        <div>
          {error && <Alert className='error-alert' key='error' variant='warning' style={{ color: '#091E42', backgroundColor: '#FFF0B3', fontWeight: 500 }}><i className='fa fa-eye' />{error} </Alert>}
        </div>
        <div className=' widget-footer'>
          <button
            style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
            className='btn btn-purple w-100 py-3 mb-5 mt-3'
            type='button'
            disabled={loading ? true : !!props.disable}
            onClick={validateAndProceed}
            themeColor={checkoutState.merchant_config?.configuration?.theme_color}
          >
            {loading ? (
              <div>
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
                Please wait
              </div>
            ) : (
              ' Proceed'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
