/* eslint-disable camelcase */
import React, { useState, useRef } from 'react'
import { CameraComponent } from '../../../camera'
import { useSelector } from 'react-redux'
import LightenDarkenColor from '../../../../util/changecolor'

export const FrontImageContent = ({ frontImageInputRef, setFrontImageCamera, handleFrontImageUpload, handleMainStep }) => {
  const [image, setimage] = useState('')
  const updateImage = (image) => {
    setimage(image)
    handleFrontImageUpload(image)
  }
  const checkoutState = useSelector((state) => state.checkout)

  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)

  const ListGroupStyle = {
    color: '#000',
    border: '0.7px solid',
    borderColor: '#ddd',
    marginBottom: 10,
    font: '15px C-bold'
  }
  const ListGroupHoverIn = (event) => {
    event.target.style.background = darkColor
    event.target.style.color = '#ffffff'
  }
  const ListGroupHoverOut = (event) => {
    event.target.style.color = '#000'
    event.target.style.background = '#ffffff'
  }
  return (
    <div className=''>
      <p className='green-text text-center'>{image !== '' ? 'Image Uploaded' : null}</p>

      {/* <h6 className='heading-3'> Select verification method</h6> */}
      <div className='list-group'>
        <div
          className=' list-group-item btn w-100 '
          style={ListGroupStyle}
          onMouseEnter={ListGroupHoverIn}
          onMouseOut={ListGroupHoverOut}
          onClick={() => {
            setFrontImageCamera(true)
            handleMainStep(2)
          }}
        >
          Take a picture
          <i className='feather icon-camera ml-2' style={{ fontWeight: '700' }} />
        </div>
        <div className='list-group-item btn w-100 ' style={ListGroupStyle} onMouseEnter={ListGroupHoverIn} onMouseOut={ListGroupHoverOut} onClick={() => frontImageInputRef.current.click()}>
          Upload file
          <i className='feather icon-upload ml-2' style={{ fontWeight: '700' }} />
        </div>
      </div>
      <div>
        <input
          ref={frontImageInputRef}
          onChange={updateImage}
          type='file'
          style={{ display: 'none' }}
          accept='.jpg, .jpeg, .png'
        />
      </div>
    </div>
  )
}

export const NINImageComponent = (props) => {
  const [frontImageCamera, setFrontImageCamera] = useState(false)
  const [frontImage, setFrontImage] = useState('')
  const [showSelfie, setShowSelfie] = useState(false)
  const [imageError, setImageError] = useState('')
  const frontImageInputRef = useRef('')
  const frontImageRef = useRef(frontImage)
  frontImageRef.current = frontImage

  const checkoutState = useSelector((state) => state.checkout)
  // const lightestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, 90)
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const getBase64 = (file, cb) => {
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result.split(',')[1])
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const updateFrontImage = (image) => {
    setFrontImage(image)
    setFrontImageCamera(false)

    props.handleMainStep(1)
  }

  const handleFrontImageUpload = async event => {
    getBase64(event.target.files[0], (result) => {
      updateFrontImage(result)
      return result
    })
  }

  const onFormSubmit = () => {
    if (!frontImage) {
      setImageError('Please choose a image')
    } else {
      var data = {
        id_front_image: frontImage,
        image: frontImage
      }

      props.updateSelfie(true, data)
      setShowSelfie(true)
    }
  }

  const verifyNIN = (image) => {
    var data = {
      id_front_image: frontImage,
      image
    }
    props.verifyNIN(data)
  }

  const dismissCamera = () => {
    setShowSelfie(false)
    setFrontImageCamera(false)
    props.handleMainStep(1)
  }

  if (frontImageCamera) {
    return <CameraComponent onCapture={updateFrontImage} dismissCamera={dismissCamera} loading={props.loading} facingMode='environment' header='Capture front image' />
  } else if (showSelfie) {
    return <CameraComponent onCapture={verifyNIN} dismissCamera={dismissCamera} loading={props.loading} error={props.error} facingMode='user' header='Capture your selfie' />
  }

  return (
    <div className='checkout'>
      <div className='checkout-container p-0'>
        <div className='header mb-3'>
          <h5 className='mt-3'>We need your NIMC slip</h5>
          <p className='mb-3'>Select how you want to give us the slip, upload or take an image</p>
          {frontImageRef.current ? <h6 className='green-text'>Image Uploaded </h6> : null}
          <FrontImageContent setFrontImageCamera={setFrontImageCamera} handleMainStep={props.handleMainStep} handleFrontImageUpload={handleFrontImageUpload} frontImageInputRef={frontImageInputRef} />
          {imageError ? <p className='error-text p-0 m-0'> {imageError} </p> : null}
          <div className=' widget-footer'>
            <button
              style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-4'
              onClick={onFormSubmit}
              type='button'
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
