import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import { BrowserRouter, Route } from 'react-router-dom'
import IdentityMainApp from './components/index'
import 'react-step-progress/dist/index.css'
import { Provider } from 'react-redux'
import store from './store'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <Route path='/:token' component={IdentityMainApp} /> */}
      <Route path='/:token' component={IdentityMainApp} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
