/* eslint-disable camelcase */
import React, { useState, useRef } from 'react'
// import StepProgressBar from 'react-step-progress'
import { CameraComponent } from '../../../camera'
import axiosInstance from '../../../../util/axios'
import { BackButton } from '../../../back_btn'
import { useSelector } from 'react-redux'
import LightenDarkenColor from '../../../../util/changecolor'

export const FrontImageContent = ({ frontImageInputRef, setFrontImageCamera, handleFrontImageUpload }) => {
  const [image, setimage] = useState('')
  const updateImage = (image) => {
    setimage(image)
    handleFrontImageUpload(image)
  }
  const checkoutState = useSelector((state) => state.checkout)

  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)

  const ListGroupStyle = {
    color: '#000',
    border: '0.7px solid',
    borderColor: '#ddd',
    marginBottom: 10,
    font: '15px C-bold'
  }
  const ListGroupHoverIn = (event) => {
    event.target.style.background = darkColor
    event.target.style.color = '#ffffff'
  }
  const ListGroupHoverOut = (event) => {
    event.target.style.color = '#000'
    event.target.style.background = '#ffffff'
  }
  return (
    <div className=''>
      <p className='green-text'>{image !== '' ? 'Image Uploaded' : null}</p>

      <div className='list-group'>
        <div className=' list-group-item btn w-100  ' style={ListGroupStyle} onMouseEnter={ListGroupHoverIn} onMouseOut={ListGroupHoverOut} onClick={() => setFrontImageCamera(true)}>
          Take License Picture
          <i className='feather icon-camera ml-2' style={{ fontWeight: '700' }} />
        </div>
        <div className='list-group-item btn w-100 ' style={ListGroupStyle} onMouseEnter={ListGroupHoverIn} onMouseOut={ListGroupHoverOut} onClick={() => frontImageInputRef.current.click()}>
          Upload License
          <i className='feather icon-upload ml-2' style={{ fontWeight: '700' }} />
        </div>

      </div>
      <div>
        <input
          ref={frontImageInputRef}
          onChange={updateImage}
          type='file'
          style={{ display: 'none' }}
        />
      </div>
    </div>
  )
}

export const DriversLicenseVerificationComponent = (props) => {
  const [frontImageCamera, setFrontImageCamera] = useState(false)
  const [frontImage, setFrontImage] = useState('')
  const [showSelfie, setShowSelfie] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [frscData, setFrscData] = useState({})
  const frontImageInputRef = useRef('')
  const frontImageRef = useRef(frontImage)
  frontImageRef.current = frontImage

  const checkoutState = useSelector((state) => state.checkout)
  // const lightestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, 90)
  const darkColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -30)
  const darkestColor = LightenDarkenColor(checkoutState.merchant_config?.configuration?.theme_color, -60)

  const getBase64 = (file, cb) => {
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result.split(',')[1])
    }
    reader.onerror = function () {
      setError('Could not process file')
    }
  }

  const updateFrontImage = (image) => {
    setFrontImage(image)
    setFrontImageCamera(false)
    // setStep(1)
  }

  const handleFrontImageUpload = async event => {
    getBase64(event.target.files[0], (result) => {
      updateFrontImage(result)
      return result
    })
  }

  const onFormSubmit = () => {
    setShowSelfie(true)
  }

  const verifyFRSC = (image) => {
    setLoading(true)
    var data = {
      id_front_image: frontImage,
      image: image,
      token: checkoutState.merchant_config?.token
    }
    // Make a request to get account information
    axiosInstance
      .post('biometrics/merchant/data/verification/library/ng/dl', data)
      .then((response) => {
        // handle success
        if (response.data.status) {
          setFrscData(response.data)
          setError('')
          props.onSuccess(frscData, 'DRIVERS-LICENSE')
        } else {
          setError(response.data.message)
        }
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        if ('detail' in error.response.data) {
          if ('number' in error.response.data.detail) {
            setError(error.response.data.detail.number[0])
          } else {
            setError(error.response.data.detail)
          }
        }
        setLoading(false)
      })
      .then(function () {
        setLoading(false)
      })
  }

  const dismissCamera = () => {
    setShowSelfie(false)
    setFrontImageCamera(false)
  }

  if (frontImageCamera) {
    return <CameraComponent onCapture={updateFrontImage} dismissCamera={dismissCamera} loading={loading} facingMode='environment' header='Capture front image' />
  } else if (showSelfie) {
    return <CameraComponent onCapture={verifyFRSC} loading={loading} dismissCamera={dismissCamera} error={error} facingMode='user' header='Capture your selfie' />
  }

  return (
    <div className='checkout'>
      <div className='checkout-container'>

        <>
          <div className='header'>
            <BackButton onClick={props.onBackBtnClick} />
            <h3 className='heading-3'>Drivers License</h3>
          </div>
          <p className='mt-5'>Select how you want to give us your license</p>
          <div className='mt-3'>
            {frontImageRef.current ? <h6 className='green-text'>Image Uploaded </h6> : null}
            <FrontImageContent setFrontImageCamera={setFrontImageCamera} handleFrontImageUpload={handleFrontImageUpload} frontImageInputRef={frontImageInputRef} />

          </div>
          <div className=' widget-footer'>
            <button
              style={{ background: `linear-gradient(${darkColor},${darkestColor})` }}
              className='btn btn-purple w-100 py-3 mb-5'
              onClick={onFormSubmit}
              type='button'
            >
              Proceed
            </button>
          </div>
        </>

      </div>
    </div>
  )
}
