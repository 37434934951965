/* eslint-disable camelcase */
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { getBanks } from '../../../store/action/'
import { connect } from 'react-redux'

class NigeriaVerificationComponent extends React.Component {
  state = {
    banks: [],
    token: '',
    error_message: '',
    showAlert: false,
    data: {},
    method: null,
    activeEndpoint: '',
    entityType: '',
    showEntitySelector: true
  }

  componentDidMount = () => {
    this.props.getBanks().then(() => { }).catch(() => { })
    this.setState({
      token: this.props.checkoutState.merchant_config.token
    })
    // check if company has no business endpoint
    var verification_endpoints = this.props.checkoutState.merchant_config?.configuration?.verification_endpoints
    var has_business = verification_endpoints?.some(value => value.endpoint?.verification_channel?.is_business)
    if (!has_business) {
      this.setState({ entityType: 'Individual', showEntitySelector: false })
    }
  }

  closeVerification = () => {
    // dispatch success event for parent element
    var event_response = {
      eventInstance: 'identity_verification_event',
      data: {
        status: 'failed',
        message: 'User Verification Cancelled'
      }
    }
    window.parent.postMessage(event_response, '*')
  }

  onSuccess = (data, method) => {
    this.setState({
      showAlert: true,
      method,
      data
    })
  }

  dipatchSuccess = () => {
    // dispatch success event for parent element
    var event_response = {
      eventInstance: 'identity_verification_event',
      data: {
        status: 'success',
        message: 'User Verified successfully',
        data: this.state.data,
        channel: this.state.method
      }
    }
    window.parent.postMessage(event_response, '*')
  }

  onBackBtnClick = () => {
    this.setState({ activeEndpoint: '' })
  }

  backToMainMenu = () => {
    this.props.setCountry('')
  }

  render() {
    return (
      <div>
        <SweetAlert
          show={this.state.showAlert}
          custom
          showCloseButton
          confirmBtnText='Return to Merchant'
          cancelBtnText='Dismiss'
          confirmBtnBsStyle='light'
          customIcon='https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg'
          title='Verification Successful'
          onConfirm={this.dipatchSuccess}
          onCancel={this.onCancel}
        >
          Your details has been received
        </SweetAlert>

        <div>
          {this.state.showEntitySelector &&
            <div className=''>
              <div className=''>
                <label>Select Entity Type</label>
                <select className='form-control' onChange={(value) => this.setState({ entityType: value.target.value })}>
                  <option>Select Entity Type</option>
                  <option value='Individual'>Individual</option>
                  <option value='Business'>Business</option>
                </select>
              </div>
            </div>}

          {(this.state.activeEndpoint === '' && this.state.entityType !== '') &&
            <div className='mt-3 mb-2'>
              <div className=''>
                <label>Select Verification Channel</label>
                <select className='form-control' onChange={(value) => this.props.setActiveEndpoint(value.target.value)}>
                  <option>Select Verification Channel</option>
                  {
                    this.props.checkoutState.merchant_config?.configuration?.verification_endpoints.map((value, index) => {
                      // filter endpoint by business or individual based on what user selects
                      if (value.endpoint?.verification_channel?.is_business && this.state.entityType === 'Business') {
                        return (
                          <option key={value.endpoint.id} value={value.endpoint.code}>{value.endpoint.name}</option>
                        )
                      } else if (!value.endpoint?.verification_channel?.is_business && this.state.entityType === 'Individual') {
                        return (
                          <option key={value.endpoint.id} value={value.endpoint.code}>{value.endpoint.name}</option>
                        )
                      }
                    })
                  }
                </select>
              </div>
            </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    checkoutState: state.checkout
  }
}

const mapDipatchToProps = (dispatch) => {
  return {
    getBanks: async () => {
      await dispatch(getBanks())
    }

  }
}

export default connect(mapStateToProps, mapDipatchToProps, null)(NigeriaVerificationComponent)
